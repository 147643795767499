import { IS_PROD } from '@/constants/config.constants';

import info from '../../../../build-number.json';
import packageJson from '../../../../package.json';

const buildNumber = info.buildNumber;
const version = packageJson.version;

const env = IS_PROD ? undefined : 'dev';
const number = buildNumber ? `(${buildNumber})` : undefined;

export const Version = () => {
  const v = [version, env, number].filter(Boolean).join('-');
  console.info(`Version: ${v}`);
  return null;
};
